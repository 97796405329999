const langEn = {
    thisIso:          'en-EN',
    pageTitles:       {
        "default":          "Stayery.",
        "mainPage":         "Cold Drinks,\nWarm Feelings.",
        "shopPage":         {
            "base":        "Spätkauf Bar",
            'hot-drinks':  'Hot drinks',
            'soft-drinks': 'Soft drinks',
            'food':        'Food',
            'meals':       'Meals',
            'snacks':      'Snacks',
            'alcohol':     'Beer & Wine',
            "drinks":      "Drinks",
            "nonfood":     "Other"
        },
        "paymentPage":      {
            "base":    "Tell us your\napartment number.",
            "waiting": "Pay now via the\npayment terminal"
        },
        "thankyouPage":     "Thanks!",
        "helppage":         "Do you\nneed help?",
        "verificationCode": "Bitte geben Sie den Verifizierungscode\nfür dieses Gerät ein"
    },
    buttons:          {
        iWantToPay: 'Continue to payment',
        clearlist:  "clear list",
        buy:        "Pay now",
        cancel:     "Cancel"
    },
    start:            {
        "goback": "Go back"
    },
    main:             {
        shopLable: {
            'hot-drinks':  'Hot drinks',
            'soft-drinks': 'Soft drinks',
            'food':        'Food',
            'meals':       'Meals',
            'snacks':      'Snacks',
            'alcohol':     'Beer & Wine',
            "drinks":      "Drinks",
            "nonfood":     "Other"
        },
        "shop":    "show me more"
    },
    cartlist:         {
        header:   'Your Goodies',
        total:    'Total',
        checkbox: 'Invoice please!'
    },
    item: {
        free: 'free',
        deposit: 'Deposit'
    },
    modal: {
        hotDrinks: {
            title: "Should I Stay <br/>or Should I GO?",
            description: "RECUP is Germany's biggest reusable system for take-away. You pay a 2€ deposit and can return the cup incl. lid at any partner nationwide and get your deposit back. Please note that STAYERY does not refund the deposit of 2€. <br/>If you have any questions, please contact a host at any time.",
            cup: "Cup",
            ownCup: "Own Cup"
        },
    },
    payment:          {
        text:    'You will receive your invoice automatically via email at point of check-out.',
        methods: 'Available payment methods:'
    },
    help:             {
        "text":    "If you got any questions or run into problems,\nplease contact one of our hosts. If they are not\non site, give us a call.",
        "number":  "+49 (0) 30 991 916 510"
    },
    verificationCode: {
        buttons: {
            "submit": "send"
        }
    },
    errors:           {
        notFound: "That's weird! We can't find your apartment. Try again! Otherwise please call us and we solve your problem.",
        general:  "An error occurred while shopping. Please try again."
    },
    warning:          {
        "payment-canceled": "The payment was canceled."
    },
    messages:         {
        timeout: "WATCH IT: You will be logged out in a few seconds if there is no activity.",
        errors:  {
            required:   "Required fields must obviously not be empty.",
            errorTitle: "Oooooops, find the error..."
        }
    },
    fields:           {
        "title":         "Your personal registration data",
        "bookingId":     "Booking number",
        "lastName":      "Surname",
        "firstName":     "Firstname",
        "birthDate":     "Date of birth",
        "email":         "E-Mail (for sending the invoice)",
        "departureDate": "Date of departure",
        "address":       "Address",
        "postalCode":    "Zipcode",
        "city":          "City",
        "country":       "Country",
        "roomnumber":    "Apartment Number",
        "signature":     "Signature",
        "company":       "Company"
    },
    countries:        {
        AF: "Afghanistan",
        AN: "Netherlands Antilles",
        AL: "Albania",
        DZ: "Algeria",
        AS: "American Samoa",
        AD: "Andorra",
        AO: "Angola",
        AI: "Anguilla",
        AQ: "Antarctica",
        AG: "Antigua and Barbuda",
        AR: "Argentina",
        AM: "Armenia",
        AW: "Aruba",
        AU: "Australia",
        AT: "Austria",
        AZ: "Azerbaijan",
        BH: "Bahrain",
        BD: "Bangladesh",
        BB: "Barbados",
        BY: "Belarus",
        BE: "Belgium",
        BZ: "Belize",
        BJ: "Benin",
        BM: "Bermuda",
        BT: "Bhutan",
        BO: "Bolivia",
        BQ: "Bonaire, Sint Eustatius and Saba",
        BA: "Bosnia and Herzegovina",
        BW: "Botswana",
        BV: "Bouvet Island",
        BR: "Brazil",
        IO: "British Indian Ocean Territory",
        VG: "British Virgin Islands",
        BN: "Brunei",
        BG: "Bulgaria",
        BF: "Burkina Faso",
        BI: "Burundi",
        KH: "Cambodia",
        CM: "Cameroon",
        CA: "Canada",
        CV: "Cape Verde",
        KY: "Cayman Islands",
        CF: "Central African Republic",
        TD: "Chad",
        CL: "Chile",
        CN: "China",
        CX: "Christmas Island",
        CC: "Cocos (Keeling) Islands",
        CO: "Colombia",
        KM: "Comoros",
        CD: "Congo",
        CG: "Congo-Brazzaville",
        CK: "Cook Islands",
        CR: "Costa Rica",
        HR: "Croatia",
        CU: "Cuba",
        CW: "Curaçao",
        CY: "Cyprus",
        CZ: "Czech Republic",
        CI: "Côte d’Ivoire",
        DK: "Denmark",
        DJ: "Djibouti",
        DM: "Dominica",
        DO: "Dominican Republic",
        EC: "Ecuador",
        EG: "Egypt",
        SV: "El Salvador",
        GQ: "Equatorial Guinea",
        ER: "Eritrea",
        EE: "Estonia",
        ET: "Ethiopia",
        FK: "Falkland Islands",
        FO: "Faroes",
        FJ: "Fiji",
        FI: "Finland",
        FR: "France",
        GF: "French Guiana",
        PF: "French Polynesia",
        TF: "French Southern Territories",
        GA: "Gabon",
        GM: "Gambia",
        GE: "Georgia",
        DE: "Germany",
        GH: "Ghana",
        GI: "Gibraltar",
        GR: "Greece",
        GL: "Greenland",
        GD: "Grenada",
        GP: "Guadeloupe",
        GU: "Guam",
        GT: "Guatemala",
        GG: "Guernsey",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HT: "Haiti",
        HM: "Heard Island and McDonald Islands",
        HN: "Honduras",
        HK: "Hong Kong SAR of China",
        HU: "Hungary",
        IS: "Iceland",
        IN: "India",
        ID: "Indonesia",
        IR: "Iran",
        IQ: "Iraq",
        IE: "Ireland",
        IM: "Isle of Man",
        IL: "Israel",
        IT: "Italy",
        JM: "Jamaica",
        JP: "Japan",
        JE: "Jersey",
        JO: "Jordan",
        KZ: "Kazakhstan",
        KE: "Kenya",
        KI: "Kiribati",
        KW: "Kuwait",
        KG: "Kyrgyzstan",
        LA: "Laos",
        LV: "Latvia",
        LB: "Lebanon",
        LS: "Lesotho",
        LR: "Liberia",
        LY: "Libya",
        LI: "Liechtenstein",
        LT: "Lithuania",
        LU: "Luxembourg",
        MO: "Macao SAR of China",
        MK: "Macedonia",
        MG: "Madagascar",
        MW: "Malawi",
        MY: "Malaysia",
        MV: "Maldives",
        ML: "Mali",
        MT: "Malta",
        MH: "Marshall Islands",
        MQ: "Martinique",
        MR: "Mauritania",
        MU: "Mauritius",
        YT: "Mayotte",
        MX: "Mexico",
        FM: "Micronesia",
        MD: "Moldova",
        MC: "Monaco",
        MN: "Mongolia",
        ME: "Montenegro",
        MS: "Montserrat",
        MA: "Morocco",
        MZ: "Mozambique",
        MM: "Myanmar",
        NA: "Namibia",
        NR: "Nauru",
        NP: "Nepal",
        NL: "Netherlands",
        NC: "New Caledonia",
        NZ: "New Zealand",
        NI: "Nicaragua",
        NE: "Niger",
        NG: "Nigeria",
        NU: "Niue",
        NF: "Norfolk Island",
        KP: "North Korea",
        MP: "Northern Marianas",
        NO: "Norway",
        OM: "Oman",
        PK: "Pakistan",
        PW: "Palau",
        PS: "Palestine",
        PA: "Panama",
        PG: "Papua New Guinea",
        PY: "Paraguay",
        PE: "Peru",
        PH: "Philippines",
        PN: "Pitcairn Islands",
        PL: "Poland",
        PT: "Portugal",
        PR: "Puerto Rico",
        QA: "Qatar",
        RE: "Reunion",
        RO: "Romania",
        RU: "Russia",
        RW: "Rwanda",
        BL: "Saint Barthélemy",
        SH: "Saint Helena, Ascension and Tristan da Cunha",
        KN: "Saint Kitts and Nevis",
        LC: "Saint Lucia",
        MF: "Saint Martin",
        PM: "Saint Pierre and Miquelon",
        VC: "Saint Vincent and the Grenadines",
        WS: "Samoa",
        SM: "San Marino",
        SA: "Saudi Arabia",
        SN: "Senegal",
        RS: "Serbia",
        SC: "Seychelles",
        SL: "Sierra Leone",
        SG: "Singapore",
        SX: "Sint Maarten",
        SK: "Slovakia",
        SI: "Slovenia",
        SB: "Solomon Islands",
        SO: "Somalia",
        ZA: "South Africa",
        GS: "South Georgia and the South Sandwich Islands",
        KR: "South Korea",
        SS: "South Sudan",
        ES: "Spain",
        LK: "Sri Lanka",
        SD: "Sudan",
        SR: "Suriname",
        SJ: "Svalbard",
        SZ: "Swaziland",
        SE: "Sweden",
        CH: "Switzerland",
        SY: "Syria",
        ST: "São Tomé e Príncipe",
        TW: "Taiwan",
        TJ: "Tajikistan",
        TZ: "Tanzania",
        TH: "Thailand",
        BS: "The Bahamas",
        TL: "Timor-Leste",
        TG: "Togo",
        TK: "Tokelau",
        TO: "Tonga",
        TT: "Trinidad and Tobago",
        TN: "Tunisia",
        TR: "Turkey",
        TM: "Turkmenistan",
        TC: "Turks and Caicos Islands",
        TV: "Tuvalu",
        VI: "US Virgin Islands",
        UG: "Uganda",
        UA: "Ukraine",
        AE: "United Arab Emirates",
        GB: "United Kingdom",
        US: "United States",
        UM: "United States Minor Outlying Islands",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VU: "Vanuatu",
        VA: "Vatican City",
        VE: "Venezuela",
        VN: "Vietnam",
        WF: "Wallis and Futuna",
        EH: "Western Sahara",
        YE: "Yemen",
        ZM: "Zambia",
        ZW: "Zimbabwe",
        AX: "Åland"
    }
};

export default langEn;
